import { cookiesConstants, getCookie, setCookie } from './cookies';
var getUniqueId = function getUniqueId() {
  var _window, _window$crypto;
  return (_window = window) == null ? void 0 : (_window$crypto = _window.crypto) == null ? void 0 : _window$crypto.randomUUID == null ? void 0 : _window$crypto.randomUUID();
};
var getTrackId = function getTrackId() {
  return getCookie(cookiesConstants.ML2_TID);
};

var generateTrackId = function generateTrackId(domain) {
  var trackId = getTrackId();
  if (!trackId) {
    trackId = getUniqueId();
    setCookie({
      domain: domain,
      name: cookiesConstants.ML2_TID,
      value: trackId
    });
  }
  return trackId;
};
export default {
  generateTrackId: generateTrackId,
  getTrackId: getTrackId
};