import Cookies from 'universal-cookie';

var setCookie = function setCookie(_ref) {
  var domain = _ref.domain,
    encode = _ref.encode,
    expires = _ref.expires,
    name = _ref.name,
    _ref$path = _ref.path,
    path = _ref$path === void 0 ? '/' : _ref$path,
    value = _ref.value;
  var cookies = new Cookies();
  var oneYear = new Date();
  var defaultDomain = domain || window.location.host;
  oneYear.setFullYear(oneYear.getFullYear() + 1);
  var expiresDate = expires || oneYear;
  cookies.set(name, value, {
    domain: defaultDomain,
    encode: encode,
    expires: expiresDate,
    path: path
  });
};
export default setCookie;