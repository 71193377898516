var magaluCompanies = {
  aliexpress: {
    icon: 'https://wx.mlcdn.com.br/site/shared/sellers/aliexpress.png'
  },
  consorcioluiza: {
    icon: 'https://wx.mlcdn.com.br/site/shared/sellers/logo-consorcio-magalu-vertical-laranja.png'
  },
  'epocacosmeticos-integra': {
    icon: 'https://wx.mlcdn.com.br/site/shared/sellers/epoca.png'
  },
  kabum: {
    icon: 'https://wx.mlcdn.com.br/site/shared/sellers/kabum.png'
  },
  lojalansolver: {
    icon: 'https://wx.mlcdn.com.br/site/shared/sellers/third-party.png'
  },
  netshoes: {
    icon: 'https://wx.mlcdn.com.br/site/shared/sellers/netshoes.png'
  },
  zattini: {
    icon: 'https://wx.mlcdn.com.br/site/shared/sellers/zattini.png'
  }
};
export default magaluCompanies;