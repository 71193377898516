import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import buildSubcategoryPath from '../../buildSubcategoryPath';
var buildCustomCategoryFilter = function buildCustomCategoryFilter(data) {
  var _data$seo, _data$seo2, _data$seo2$category, _data$seo2$category$, _data$seo2$category$$, _data$seo2$category$$2, _data$category, _data$category$;
  var structure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var filterType = 'category';
  var filterLabel = 'Categoria';
  var _ref = (data == null ? void 0 : data.category) || (data == null ? void 0 : (_data$seo = data.seo) == null ? void 0 : _data$seo.category) || [],
    _ref2 = _slicedToArray(_ref, 1),
    _ref2$ = _ref2[0],
    category = _ref2$ === void 0 ? {} : _ref2$;
  var _ref3 = (structure == null ? void 0 : structure.route) || {},
    subCategoryId = _ref3.subCategoryId;
  var childrenSubCategories = subCategoryId ? data == null ? void 0 : (_data$seo2 = data.seo) == null ? void 0 : (_data$seo2$category = _data$seo2.category) == null ? void 0 : (_data$seo2$category$ = _data$seo2$category[0]) == null ? void 0 : (_data$seo2$category$$ = _data$seo2$category$.subcategories) == null ? void 0 : (_data$seo2$category$$2 = _data$seo2$category$$[0]) == null ? void 0 : _data$seo2$category$$2.subcategories : data == null ? void 0 : (_data$category = data.category) == null ? void 0 : (_data$category$ = _data$category[0]) == null ? void 0 : _data$category$.subcategories;
  var filters = childrenSubCategories ? childrenSubCategories.map(function (sub, idx) {
    var subcategory = buildSubcategoryPath(sub, category);
    subcategory.href = "".concat((structure == null ? void 0 : structure.basePath) || '').concat(subcategory.path);
    subcategory.position = idx + 1;
    subcategory.filterLabel = filterLabel;
    subcategory.filterSlug = filterType;
    return subcategory;
  }) : [];
  return {
    label: filterLabel,
    slug: filterType,
    type: filterType,
    values: filters
  };
};
export default buildCustomCategoryFilter;